<template>
<a-form :model="loginForm" layout="vertical" @finish="loginUser">
    <div class="field">
        <a-form-item name="email" :rules="[{ required: true, message: $t('login.ePlaceholder')+' '+$t('global.validation')  }]">
            <a-input v-model:value="loginForm.email" :placeholder="$t('login.ePlaceholder')" size="large" />
        </a-form-item>
    </div>
    <div class="field">
        <a-form-item name="password" :rules="[{ required: true, message: $t('login.psdPlaceholder')+' '+$t('global.validation')  }]">
            <a-input-password v-model:value="loginForm.password" :placeholder="$t('login.psdPlaceholder')" size="large" />
        </a-form-item>
    </div>
    <div class="field">
        <a-form-item>
            <a-checkbox v-model:checked="rememberMe" :defaultChecked="rememberMe">Remember Me</a-checkbox>
        </a-form-item>
    </div>
    <div class="field">
        <span class="error">{{ errorMsg.errorMsg }}</span>
    </div>
    <div class="buttons">
        <a-button type="primary" html-type="submit" :loading="iconLoading">{{$t("login.login")}}</a-button>
        <!-- <router-link :to="{ name: 'ForgotPassword', params: { udid: '' }}"> -->
        <router-link to="/forgot-password">
            {{ $t("login.forgotPassword") }}
        </router-link>
    </div>
</a-form>
</template>

<script>
import {
    computed,
    reactive,
    ref,
    onUnmounted,
    watchEffect
} from "vue";
import {
    useRouter
} from "vue-router";
import {
    useStore
} from "vuex";
import {
  encrypt
} from '@/commonMethods/commonMethod'; 
export default {
    setup() {
        const store = useStore()
        const router = useRouter();
        const rememberMe = ref(true)
        const loginForm = reactive({
            email: "",
            password: "",
        })
        const iconLoading = ref(false)
        const crypt = (salt, text) => {
            const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
            const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
            const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

            return text
                .split("")
                .map(textToChars)
                .map(applySaltToChar)
                .map(byteHex)
                .join("");
        };

        const decrypt = (salt, encoded) => {
            const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
            const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
            return encoded
                .match(/.{1,2}/g)
                .map((hex) => parseInt(hex, 16))
                .map(applySaltToChar)
                .map((charCode) => String.fromCharCode(charCode))
                .join("");
        };

        const loginUser = () => {
            iconLoading.value = true
            if (rememberMe.value) {
                localStorage.setItem('email', loginForm.email)

                localStorage.setItem('password', crypt(encrypt, loginForm.password));

            }else{
                 localStorage.removeItem('email')

                localStorage.removeItem('password');
            }

            store.dispatch('login', loginForm).then(() => {
                store.dispatch("dateFilter", 46);
                store.dispatch("globalCodes");
                store.dispatch("permissions");
                store.dispatch("escalationStaus")
                store.dispatch("appointmentConference");
                store.dispatch("notificationList");
                store.dispatch("allPatientsList")
                store.dispatch("allStaffList")
            });
        }
        const error = computed(() => {
            return store.state.dashBoard.errorMsg
        })
        watchEffect(() => {
            if (localStorage.getItem('email')) {
                loginForm.email = localStorage.getItem('email')
            }
            if (localStorage.getItem('password')) {
                let ciphertext = localStorage.getItem('password')

                loginForm.password = decrypt(encrypt, ciphertext)
            }
            if (error.value) {
                iconLoading.value = false
            }
        })
        onUnmounted(() => {
            iconLoading.value = false
            store.state.dashBoard.errorMsg = ''

        })
        const loggedInUser = computed(() => {
            return store.state.authentication.loggedInUser
        })

        const errorMsg = computed(() => {
            return store.state.authentication
        })

        return {
            errorMsg,
            loginForm,
            loginUser,
            router,
            iconLoading,
            loggedInUser,
            rememberMe
        };
    },
};
</script>

<style scoped>
span.error {
    color: #ff0000;
}
</style>
